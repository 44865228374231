import FilledButton from 'primitives/Buttons/FilledButton';
import styled from '@emotion/styled';

const LoadMoreButton = styled(FilledButton)({
  display: 'block',
  marginBottom: '10rem',
  marginLeft: 'auto',
  marginRight: 'auto',
});

export default LoadMoreButton;
